import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from 'gatsby';
import "../css/style.scss";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Header from "../components/header";
import CarouselBackgrounds from "../components/background-slider";
import CarouselForegrounds from "../components/foreground-slider";
import SocialIcons from "../components/social-icons";
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
} from "react-share";

/** Videos */

import SlidesData from "../../slides.json";

const Sights = () => {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [isMobile, setIsMobile] = useState(false);

    /** Get Videos from File System and put the static links into the JSON data */
    const data = useStaticQuery(graphql`
        query {
            allFile {
                edges {
                    node {
                        publicURL
                        name
                        ext
                    }
                }
            }
            site {
                siteMetadata {
                  title
                  description
                  baseUrl
                  siteUrl
                  social {
                    facebook
                    twitter
                    instagram
                    linkedin
                  }
                }
              }
        }
  `)

    data.allFile.edges.map((file) => {
        if (file.node.ext === '.mp4') {
            SlidesData.forEach((slide, index) => {
                if (slide.desktopVideo === file.node.name) {
                    SlidesData[index].desktopVideo = file.node.publicURL
                } else if (slide.mobileVideo === file.node.name) {
                    SlidesData[index].mobileVideo = file.node.publicURL
                }
            })
        }
        else if (file.node.ext === '.jpg' && file.node.name.includes('poster')) {
            SlidesData.forEach((slide, index) => {
                if (slide.desktopPoster === file.node.name) {
                    SlidesData[index].desktopPoster = file.node.publicURL
                } else if (slide.mobilePoster === file.node.name) {
                    SlidesData[index].mobilePoster = file.node.publicURL
                }
            })
        }
        else if (file.node.ext === '.svg' && file.node.name.includes('map')) {
            SlidesData.forEach((slide, index) => {
                if (slide.map === file.node.name) {
                    SlidesData[index].map = file.node.publicURL
                }
            })
        }
    })

    SlidesData.sort((a, b) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0))

    useEffect(() => {
        /** If the screen is mobile sized */
        if (window.innerWidth <= 768) {
            setIsMobile(true)
        }
    }, [setIsMobile])

    return (
        <Layout>
            <Seo />
            <Header />
            <main>
                <section id="carousel" className="h-screen overflow-hidden relative">
                    <div className="horizontalLine absolute top-1/2 transform -translate-y-50 right-0 w-10 md:w-28 h-0.5 bg-white z-20"></div>
                    <CarouselForegrounds firstSwiper={firstSwiper} setSecondSwiper={setSecondSwiper} data={SlidesData} />
                    <CarouselBackgrounds secondSwiper={secondSwiper} setFirstSwiper={setFirstSwiper} data={SlidesData} isMobile={isMobile} />
                    <div className="flex md:hidden items-center space-x-5 absolute bottom-5 left-1/2 transform -translate-x-1/2 z-30">
                        <TwitterShareButton url={data.site.siteMetadata.siteUrl} title={data.site.siteMetadata.title} via={data.site.siteMetadata.twitterHandle} related={data.site.siteMetadata.twitterHandle}>
                            <SocialIcons type="twitter" />
                        </TwitterShareButton>
                        <FacebookShareButton url={data.site.siteMetadata.siteUrl} quote={data.site.siteMetadata.description}>
                            <SocialIcons type="facebook" />
                        </FacebookShareButton>
                        <LinkedinShareButton url={data.site.siteMetadata.siteUrl} title={data.site.siteMetadata.title} summary={data.site.siteMetadata.description} source="Upgraded Points">
                            <SocialIcons type="linkedin" />
                        </LinkedinShareButton>
                    </div>
                </section>
            </main>
        </Layout>
    );
};

export default Sights;
