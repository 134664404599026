import React, { useEffect } from "react";
import { Navigation, Pagination, Scrollbar, A11y, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LazyVideo } from 'react-lazy-media';
import AniLink from "gatsby-plugin-transition-link/AniLink";
import BackButton from "../images/svg/back.svg";

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';

const CarouselBackgrounds = (props) => {
    const slides = props.data.map((element, index) => {
        return <SwiperSlide>
            <LazyVideo id={`video-${index}`} classes={['lazy-video']} src={props.isMobile ? element.mobileVideo : element.desktopVideo} poster={props.isMobile ? element.mobilePoster : element.desktopPoster} control={false} preload={false} autoplay={false} loop={true} muted={true} />

            <AniLink fade to="/#section3" id="goToIndex" className="hidden sm:block absolute top-20 left-5 md:left-10" style={{ zIndex: 999 }}>
                <BackButton />
            </AniLink>

            <div className="w-full max-w-xs sm:max-w-lg md:max-w-xl lg:max-w-2xl z-10 px-5 md:px-10 pr-10 md:pr-0 absolute max-h-screen h-full justify-center flex flex-col mt-0 lg:mt-20">
                <span className="block uppercase text-white text-oswald text-xl my-1">{element.route}</span>
                <h1 className="uppercase text-white text-lg md:text-2xl lg:text-5xl font-bold">{element.name}</h1>
                <p className="text-white text-sm lg:text-base mt-4">{element.description}</p>
                <img src={element.map} className="mt-20 mb-10 block" width="250" alt={element.name} />
            </div>

        </SwiperSlide>
    })

    useEffect(() => {
        /** Play the first video immediatley */
        document.getElementById('video-0').play();
    }, [])

    return (
        <Swiper
            id="backgrounds"
            modules={[Navigation, Pagination, Scrollbar, A11y, Controller]}
            controller={{ control: props.secondSwiper }}
            spaceBetween={0}
            slidesPerView={1}
            direction="horizontal"
            onSwiper={(swiper) => props.setFirstSwiper(swiper)}
            onSlideChange={(slide) => {
                document.querySelectorAll('.lazy-video')[slide.activeIndex].play()
            }}
        >
            {slides}
        </Swiper>
    )
}

export default CarouselBackgrounds